.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
  background-color: #fff;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #01053b;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-1 > div, .hamburger.hamburger-1 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-1 > div::after, .hamburger.hamburger-1 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1 > div::before, .hamburger.hamburger-1 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div, .hamburger.hamburger-1.active view {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-1.active > div::after, .hamburger.hamburger-1.active view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div::before, .hamburger.hamburger-1.active view::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-2 > div, .hamburger.hamburger-2 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-2 > div::after, .hamburger.hamburger-2 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2 > div::before, .hamburger.hamburger-2 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div, .hamburger.hamburger-2.active > view {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-2.active > div::after, .hamburger.hamburger-2.active > view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div::before, .hamburger.hamburger-2.active > view::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-3 > div, .hamburger.hamburger-3 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-3 > div::before, .hamburger.hamburger-3 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-3 > div::after, .hamburger.hamburger-3 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-3.active > div, .hamburger.hamburger-3.active > view {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-3.active > div::before, .hamburger.hamburger-3.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-3.active > div:after, .hamburger.hamburger-3.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4 > div, .hamburger.hamburger-4 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-4 > div::before, .hamburger.hamburger-4 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-4 > div::after, .hamburger.hamburger-4 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-4.active > div, .hamburger.hamburger-4.active > view {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4.active > div::before, .hamburger.hamburger-4.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-4.active > div:after, .hamburger.hamburger-4.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-5 > div, .hamburger.hamburger-5 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::before, .hamburger.hamburger-5 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::after, .hamburger.hamburger-5 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div, .hamburger.hamburger-5.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-5.active > div::before, .hamburger.hamburger-5.active > view::before {
  left: -56px;
  top: -56px;
  transform: translate3d(56px, 56px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div::after, .hamburger.hamburger-5.active > view::after {
  right: -56px;
  top: -56px;
  transform: translate3d(-56px, 56px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6 > div, .hamburger.hamburger-6 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::before, .hamburger.hamburger-6 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::after, .hamburger.hamburger-6 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div, .hamburger.hamburger-6.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-6.active > div::before, .hamburger.hamburger-6.active > view::before {
  left: -56px;
  top: 56px;
  transform: translate3d(56px, -56px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div::after, .hamburger.hamburger-6.active > view::after {
  right: -56px;
  top: 56px;
  transform: translate3d(-56px, -56px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-7 > div, .hamburger.hamburger-7 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-7 > div::before, .hamburger.hamburger-7 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-7 > div::after, .hamburger.hamburger-7 > view::after {
  top: 16px;
}

.hamburger.hamburger-7.active > div, .hamburger.hamburger-7.active > view {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger.hamburger-7.active > div::before, .hamburger.hamburger-7.active > view::before {
  transform: rotate(-45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-7.active > div::after, .hamburger.hamburger-7.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.hamburger.hamburger-8 > div, .hamburger.hamburger-8 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-8 > div::before, .hamburger.hamburger-8 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-8 > div::after, .hamburger.hamburger-8 > view::after {
  top: 16px;
}

.hamburger.hamburger-8.active > div, .hamburger.hamburger-8.active > view {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger.hamburger-8.active > div::before, .hamburger.hamburger-8.active > view::before {
  transform: rotate(45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-8.active > div::after, .hamburger.hamburger-8.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

.hamburger.hamburger-9 > div, .hamburger.hamburger-9 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-9 > div::before, .hamburger.hamburger-9 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-9 > div::after, .hamburger.hamburger-9 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div, .hamburger.hamburger-9.active > view {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-9.active > div::before, .hamburger.hamburger-9.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div::after, .hamburger.hamburger-9.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-10 > div, .hamburger.hamburger-10 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-10 > div::before, .hamburger.hamburger-10 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-10 > div::after, .hamburger.hamburger-10 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div, .hamburger.hamburger-10.active > view {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-10.active > div::before, .hamburger.hamburger-10.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div::after, .hamburger.hamburger-10.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 1;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
  background: #fff;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
  background: #fff;
}

.hamburger.hamburger-12 > div, .hamburger.hamburger-12 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::before, .hamburger.hamburger-12 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > view::before {
  transition-property: top, opacity, background-color;
}

.hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-12.active > div, .hamburger.hamburger-12.active > view {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::before, .hamburger.hamburger-12.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger.hamburger-13 > div, .hamburger.hamburger-13 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::before, .hamburger.hamburger-13 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > view::before {
  transition-property: top, opacity;
}

.hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-13.active > div, .hamburger.hamburger-13.active > view {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::before, .hamburger.hamburger-13.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.index .index-banner .swiper-slide img {
  max-width: 100%;
}

.index .index-banner .container {
  position: relative;
}

.index .index-banner .container .swiper-pagination {
  left: 0;
  width: fit-content;
  bottom: 1.57rem;
}

@media (max-width: 991px) {
  .index .index-banner .container .swiper-pagination {
    left: 20px;
  }
}

@media (max-width: 991px) {
  .index .index-banner .container .swiper-pagination {
    bottom: 10px;
  }
}

.index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin: 0;
  margin-right: 10px;
  opacity: 1;
  background: #fff;
}

@media (max-width: 1600px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    width: 11.52px;
  }
}

@media (max-width: 1366px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    width: 11.04px;
  }
}

@media (max-width: 1024px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    width: 10.56px;
  }
}

@media (max-width: 991px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    width: 10.08px;
  }
}

@media (max-width: 767px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    width: 9.6px;
  }
}

@media (max-width: 1600px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    height: 11.52px;
  }
}

@media (max-width: 1366px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    height: 11.04px;
  }
}

@media (max-width: 1024px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    height: 10.56px;
  }
}

@media (max-width: 991px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    height: 10.08px;
  }
}

@media (max-width: 767px) {
  .index .index-banner .container .swiper-pagination .swiper-pagination-bullet {
    height: 9.6px;
  }
}

.index .index-banner .container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #c21010;
}

.index .index-banner .container .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.index .index-banner .container .pc-search {
  position: absolute;
  bottom: 0.6rem;
  left: 0;
  z-index: 10;
  width: 900px;
  height: 56px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .index .index-banner .container .pc-search {
    display: none;
  }
}

.index .index-banner .container .pc-search h2 {
  font-family: D-DIN-Bold;
  font-size: 24px;
  color: #ffffff;
}

.index .index-banner .container .pc-search .select {
  position: relative;
  width: 180px;
  height: 40px;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  border-radius: 2px;
  border: solid 1px #ffffff;
  color: #fff;
}

.index .index-banner .container .pc-search .select:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -4px;
  background: url(./images/index-select.png) no-repeat;
  width: 15px;
  height: 9px;
  background-size: 100% 100%;
}

.index .index-banner .container .pc-search .select .select_text {
  padding: 0 20px 0 10px;
  height: 40px;
  line-height: 36px;
}

.index .index-banner .container .pc-search .select .select_ul {
  display: none;
  position: absolute;
  top: 40px;
  left: -1px;
  width: 100%;
  border: 1px solid #D4D4D4;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #fff;
  color: #202020;
  max-height: 350px;
  overflow-y: auto;
}

.index .index-banner .container .pc-search .select .select_ul li {
  line-height: 36px;
  text-indent: 10px;
}

.index .index-banner .container .pc-search .select .select_ul li:hover {
  color: #fff;
  background: #6AA7EA;
}

.index .index-banner .container .pc-search .select .select_ul li.cur {
  color: #fff;
  background: #195DA3;
}

.index .index-banner .container .pc-search .select.change1 .select_ul li:first-child {
  display: none;
}

.index .index-banner .container .pc-search input {
  display: block;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #c21010;
  border-radius: 1px;
  font-family: D-DIN-Bold;
  font-size: 20px;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}

.index .section1 {
  margin-top: 100px;
  margin-bottom: 170px;
}

@media (max-width: 1600px) {
  .index .section1 {
    margin-top: 88px;
  }
}

@media (max-width: 1366px) {
  .index .section1 {
    margin-top: 76px;
  }
}

@media (max-width: 1024px) {
  .index .section1 {
    margin-top: 64px;
  }
}

@media (max-width: 991px) {
  .index .section1 {
    margin-top: 52px;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    margin-top: 40px;
  }
}

@media (max-width: 1600px) {
  .index .section1 {
    margin-bottom: 149.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 {
    margin-bottom: 129.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 {
    margin-bottom: 108.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 {
    margin-bottom: 88.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    margin-bottom: 68px;
  }
}

.index .section1 .tyre-nav {
  margin-bottom: 60px;
  overflow-x: hidden;
}

@media (max-width: 1600px) {
  .index .section1 .tyre-nav {
    margin-bottom: 52.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .tyre-nav {
    margin-bottom: 45.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .tyre-nav {
    margin-bottom: 38.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 .tyre-nav {
    margin-bottom: 31.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 .tyre-nav {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .index .section1 .tyre-nav {
    overflow-x: auto;
  }
}

.index .section1 .tyre-nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 767px) {
  .index .section1 .tyre-nav ul {
    width: fit-content;
  }
}

.index .section1 .tyre-nav ul li {
  padding: 6px 10px;
  border-radius: 2px;
  transition: all .3s;
  margin-right: 48px;
  font-size: 18px;
  color: #202020;
  white-space: wrap;
}

@media (max-width: 1600px) {
  .index .section1 .tyre-nav ul li {
    margin-right: 42.4px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .tyre-nav ul li {
    margin-right: 36.8px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .tyre-nav ul li {
    margin-right: 31.2px;
  }
}

@media (max-width: 991px) {
  .index .section1 .tyre-nav ul li {
    margin-right: 25.6px;
  }
}

@media (max-width: 767px) {
  .index .section1 .tyre-nav ul li {
    margin-right: 20px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .tyre-nav ul li {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .tyre-nav ul li {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .tyre-nav ul li {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 .tyre-nav ul li {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 .tyre-nav ul li {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .index .section1 .tyre-nav ul li {
    white-space: nowrap;
  }
}

.index .section1 .tyre-nav ul li.active, .index .section1 .tyre-nav ul li:hover {
  background-color: #01053b;
  color: #fff;
}

.index .section1 .swiper-list .swipers {
  display: none;
  position: relative;
}

.index .section1 .swiper-list .swipers.active {
  display: block;
}

.index .section1 .swiper-list .swipers .swiper-button-next {
  top: 37%;
  right: -1.2rem;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 2px;
  border: solid 1px #7d7d7d;
  transition: all .3s;
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    right: 0;
  }
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    width: 48px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    width: 46px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    width: 44px;
  }
}

@media (max-width: 991px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    width: 42px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    height: 48px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    height: 46px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    height: 44px;
  }
}

@media (max-width: 991px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    height: 42px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swipers .swiper-button-next {
    height: 40px;
  }
}

.index .section1 .swiper-list .swipers .swiper-button-next span {
  font-size: 28px;
  color: #202020;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swipers .swiper-button-next span {
    font-size: 26.88px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swipers .swiper-button-next span {
    font-size: 25.76px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swipers .swiper-button-next span {
    font-size: 24.64px;
  }
}

@media (max-width: 991px) {
  .index .section1 .swiper-list .swipers .swiper-button-next span {
    font-size: 23.52px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swipers .swiper-button-next span {
    font-size: 22.4px;
  }
}

.index .section1 .swiper-list .swipers .swiper-button-next:after {
  content: '';
}

.index .section1 .swiper-list .swipers .swiper-button-next:hover {
  background: #C21010;
  border-color: #C21010;
}

.index .section1 .swiper-list .swipers .swiper-button-next:hover span {
  color: #fff;
}

.index .section1 .swiper-list .swipers .swiper-button-prev {
  top: 37%;
  left: -1.2rem;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 2px;
  border: solid 1px #7d7d7d;
  transition: all .3s;
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    left: 0;
  }
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    width: 48px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    width: 46px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    width: 44px;
  }
}

@media (max-width: 991px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    width: 42px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    height: 48px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    height: 46px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    height: 44px;
  }
}

@media (max-width: 991px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    height: 42px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev {
    height: 40px;
  }
}

.index .section1 .swiper-list .swipers .swiper-button-prev span {
  font-size: 28px;
  color: #202020;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev span {
    font-size: 26.88px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev span {
    font-size: 25.76px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev span {
    font-size: 24.64px;
  }
}

@media (max-width: 991px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev span {
    font-size: 23.52px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swipers .swiper-button-prev span {
    font-size: 22.4px;
  }
}

.index .section1 .swiper-list .swipers .swiper-button-prev:after {
  content: '';
}

.index .section1 .swiper-list .swipers .swiper-button-prev:hover {
  background: #C21010;
  border-color: #C21010;
}

.index .section1 .swiper-list .swipers .swiper-button-prev:hover span {
  color: #fff;
}

.index .section1 .swiper-list .swiper .swiper-slide .pic {
  width: fit-content;
  margin: 0;
  height: 441px;
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swiper .swiper-slide .pic {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swiper .swiper-slide .pic {
    margin: auto;
  }
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swiper .swiper-slide .pic {
    height: 360px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swiper .swiper-slide .pic {
    height: 330px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swiper .swiper-slide .pic {
    height: 265px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swiper .swiper-slide .pic {
    height: auto;
  }
}

.index .section1 .swiper-list .swiper .swiper-slide .pic img {
  width: 100%;
  margin: auto;
  max-width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swiper .swiper-slide .pic img {
    width: fit-content;
  }
}

.index .section1 .swiper-list .swiper .swiper-slide .text {
  margin-top: 10px;
}

.index .section1 .swiper-list .swiper .swiper-slide .text h2 {
  font-family: D-DIN-Bold;
  font-size: 36px;
  width: fit-content;
  margin: auto;
  color: #b2b2b2;
  transition: all .3s;
  margin-bottom: 5px;
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text h2 {
    font-size: 33.12px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text h2 {
    font-size: 30.24px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text h2 {
    font-size: 27.36px;
  }
}

@media (max-width: 991px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text h2 {
    font-size: 24.48px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text h2 {
    font-size: 21.6px;
  }
}

.index .section1 .swiper-list .swiper .swiper-slide .text p {
  font-family: D-DIN-Bold;
  font-size: 16px;
  padding: 3px 12px;
  color: #b2b2b2;
  transition: all .3s;
  border-radius: 13px;
  border: solid 1px #b2b2b2;
  width: fit-content;
  margin: auto;
}

@media (max-width: 1600px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text p {
    font-size: 15.2px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text p {
    font-size: 13.6px;
  }
}

@media (max-width: 991px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text p {
    font-size: 12.8px;
  }
}

@media (max-width: 767px) {
  .index .section1 .swiper-list .swiper .swiper-slide .text p {
    font-size: 12px;
  }
}

.index .section1 .swiper-list .swiper .swiper-slide:hover .text h2 {
  color: #01053b;
}

.index .section1 .swiper-list .swiper .swiper-slide:hover .text p {
  color: #c21010;
  border-color: #c21010;
}

.index .section2 {
  background: url(./images/index-bg.png) no-repeat;
  background-size: cover;
}

.index .section2 .top {
  position: relative;
}

.index .section2 .top .pic img {
  max-width: 100%;
}

.index .section2 .top .container .left {
  position: relative;
  width: 724px;
  margin: 0;
}

@media (max-width: 767px) {
  .index .section2 .top .container .left {
    width: 96%;
  }
}

@media (max-width: 767px) {
  .index .section2 .top .container .left {
    margin: auto;
  }
}

.index .section2 .top .container .left .text {
  position: relative;
  top: -47px;
  margin-bottom: 20px;
}

.index .section2 .top .container .left .text h2 {
  font-family: D-DIN-Bold;
  font-size: 66px;
  color: #202020;
  margin-bottom: 10px;
}

@media (max-width: 1600px) {
  .index .section2 .top .container .left .text h2 {
    font-size: 60.72px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .top .container .left .text h2 {
    font-size: 55.44px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .top .container .left .text h2 {
    font-size: 50.16px;
  }
}

@media (max-width: 991px) {
  .index .section2 .top .container .left .text h2 {
    font-size: 44.88px;
  }
}

@media (max-width: 767px) {
  .index .section2 .top .container .left .text h2 {
    font-size: 39.6px;
  }
}

.index .section2 .top .container .left .text p {
  font-size: 18px;
  line-height: 28px;
  color: #202020;
}

@media (max-width: 1600px) {
  .index .section2 .top .container .left .text p {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .top .container .left .text p {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .top .container .left .text p {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 .top .container .left .text p {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 .top .container .left .text p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index .section2 .top .container .left .text p {
    line-height: 27.37778px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .top .container .left .text p {
    line-height: 26.75556px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .top .container .left .text p {
    line-height: 26.13333px;
  }
}

@media (max-width: 991px) {
  .index .section2 .top .container .left .text p {
    line-height: 25.51111px;
  }
}

@media (max-width: 767px) {
  .index .section2 .top .container .left .text p {
    line-height: 24.88889px;
  }
}

.index .section2 .top .container .left .text a {
  display: block;
  width: fit-content;
  color: #202020;
  font-size: 16px;
  margin-top: 20px;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section2 .top .container .left .text a {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .top .container .left .text a {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .top .container .left .text a {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 .top .container .left .text a {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 .top .container .left .text a {
    font-size: 14px;
  }
}

.index .section2 .top .container .left .text a img {
  margin-left: 16px;
}

.index .section2 .top .container .left .text a:hover .scroll-cont div {
  color: #C21010;
  transform: translateY(-100%);
}

.index .section2 .top .container .left .text a:hover .scroll-cont div img {
  opacity: 1;
}

.index .section2 .top .container .left .text a .scroll-cont {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 20px;
  transition: all .3s;
}

@media (max-width: 767px) {
  .index .section2 .top .container .left .text a .scroll-cont {
    height: 18px;
  }
}

.index .section2 .top .container .left .text a .scroll-cont div {
  display: flex;
  align-items: center;
  transition: all .25s;
  color: #202020;
}

.index .section2 .top .container .left .text a .scroll-cont div img {
  opacity: 1;
}

.index .section2 .top .container .left .swiper .swiper-slide {
  position: relative;
}

.index .section2 .top .container .left .swiper .swiper-slide .videopic {
  max-width: 100%;
}

.index .section2 .top .container .left .swiper .swiper-slide .play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.index .section2 .top .container .left .swiper-pagination {
  left: 0;
  right: auto;
  width: fit-content;
  bottom: -28px;
}

.index .section2 .top .container .left .swiper-pagination .swiper-pagination-bullet {
  width: 30px;
  height: 2px;
  border-radius: 0;
  background-color: #bfbfbf;
  opacity: 1;
}

.index .section2 .top .container .left .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #c21010;
}

.index .section2 .top .pic {
  position: absolute;
  right: 0;
  top: -72px;
  width: 56.16%;
  display: block;
}

@media (max-width: 1366px) {
  .index .section2 .top .pic {
    top: 0;
  }
}

@media (max-width: 991px) {
  .index .section2 .top .pic {
    display: none;
  }
}

.index .section2 .bottom {
  margin-top: 160px;
  padding-bottom: 160px;
}

@media (max-width: 1600px) {
  .index .section2 .bottom {
    margin-top: 140.8px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .bottom {
    margin-top: 121.6px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .bottom {
    margin-top: 102.4px;
  }
}

@media (max-width: 991px) {
  .index .section2 .bottom {
    margin-top: 83.2px;
  }
}

@media (max-width: 767px) {
  .index .section2 .bottom {
    margin-top: 64px;
  }
}

@media (max-width: 1600px) {
  .index .section2 .bottom {
    padding-bottom: 140.8px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .bottom {
    padding-bottom: 121.6px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .bottom {
    padding-bottom: 102.4px;
  }
}

@media (max-width: 991px) {
  .index .section2 .bottom {
    padding-bottom: 83.2px;
  }
}

@media (max-width: 767px) {
  .index .section2 .bottom {
    padding-bottom: 64px;
  }
}

.index .section2 .bottom .container ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .index .section2 .bottom .container ul {
    flex-wrap: wrap;
  }
}

.index .section2 .bottom .container ul li {
  width: 350px;
  position: relative;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .index .section2 .bottom .container ul li {
    width: 24.3%;
  }
}

@media (max-width: 767px) {
  .index .section2 .bottom .container ul li {
    width: 48%;
  }
}

@media (max-width: 767px) {
  .index .section2 .bottom .container ul li {
    margin-bottom: 15px;
  }
}

.index .section2 .bottom .container ul li .more {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.4);
  transition: all .3s;
}

.index .section2 .bottom .container ul li .more img {
  width: fit-content;
}

@media (max-width: 1600px) {
  .index .section2 .bottom .container ul li .more img {
    width: 80%;
  }
}

.index .section2 .bottom .container ul li:hover .more {
  background: transparent;
}

.video-model {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
}

.video-model .video {
  width: 800px;
}

@media (max-width: 767px) {
  .video-model .video {
    width: 100%;
  }
}

.video-model .video video {
  width: 100%;
}

.video-model .out {
  position: absolute;
  top: 1rem;
  right: 2rem;
  width: 50px;
}

@media (max-width: 767px) {
  .video-model .out {
    right: 20px;
  }
}

@media (max-width: 1600px) {
  .video-model .out {
    width: 48px;
  }
}

@media (max-width: 1366px) {
  .video-model .out {
    width: 46px;
  }
}

@media (max-width: 1024px) {
  .video-model .out {
    width: 44px;
  }
}

@media (max-width: 991px) {
  .video-model .out {
    width: 42px;
  }
}

@media (max-width: 767px) {
  .video-model .out {
    width: 40px;
  }
}

footer {
  display: block;
}

@media (max-width: 991px) {
  footer {
    display: none;
  }
}

.yd-search {
  z-index: 9;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  padding: 0 10px;
  background: #fff;
  display: none;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .yd-search {
    display: flex;
  }
}

.yd-search .select {
  position: relative;
  width: 24%;
  width: 24%;
  height: 36px;
  background-color: #202020;
  cursor: pointer;
  font-size: 14px;
  border-radius: 1px;
  color: #fff;
}

.yd-search .select:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -2px;
  background: url(./images/index-select.png) no-repeat;
  width: 10px;
  height: 6px;
  background-size: 100% 100%;
}

.yd-search .select .select_text {
  padding: 0 20px 0 10px;
  height: 36px;
  line-height: 36px;
}

.yd-search .select .select_ul {
  display: none;
  position: absolute;
  bottom: 36px;
  left: -1px;
  width: 100%;
  border: 1px solid #D4D4D4;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #fff;
  max-height: 350px;
  overflow-y: auto;
  color: #202020;
}

.yd-search .select .select_ul li {
  line-height: 36px;
  text-indent: 10px;
}

.yd-search .select .select_ul li:hover {
  color: #fff;
  background: #6AA7EA;
}

.yd-search .select .select_ul li.cur {
  color: #fff;
  background: #195DA3;
}

.yd-search .select.change1_hd .select_ul li:first-child {
  display: none;
}

.yd-search input {
  display: block;
  width: 16%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #c21010;
  border-radius: 1px;
  font-family: D-DIN-Bold;
  font-size: 14px;
  color: #ffffff;
  border: none;
  outline: none;
}
