@import "../../assets/styles/utils";
.index {
    .index-banner {
        .swiper-slide {
            img {
                max-width: 100%;
            }
        }
        .container {
            position: relative;
            .swiper-pagination {
                @include res(left,0,(sm:20px));
                width: fit-content;
                @include res(bottom,1.57rem,(sm:10px));
                .swiper-pagination-bullet {
                    @include res(width,12px,(4 / 5));
                    @include res(height,12px,(4 / 5));
                    margin: 0;
                    @include res(margin-right,10px);
                    opacity: 1;
                    background: #fff;
                    &.swiper-pagination-bullet-active {
                        background-color: #c21010;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            
            .pc-search {
                position: absolute;
                @include res(bottom,0.6rem);
                left: 0;
                z-index: 10;
                @include res(width,900px);
                @include res(height,56px);
                background: rgba(0,0,0,.6);
                @include res(display,flex,(sm:none));
                padding: 0 10px;
                align-items: center;
                justify-content: space-between;
                h2 {
                    font-family: D-DIN-Bold;
                    @include res(font-size,24px);
                    color: #ffffff;
                }
                .select {
                    position:relative;
                    width:180px;
                    height:40px;
                    background-color: transparent;
                    cursor:pointer;
                    font-size: 18px;
                    border-radius: 2px;
                    border: solid 1px #ffffff;
                    color: #fff;
                    &:after {
                        content:"";
                        position:absolute;
                        top:50%;
                        right:10px;
                        margin-top:-4px;
                        background: url(./images/index-select.png)no-repeat;
                        width: 15px;
                        height: 9px;
                        background-size: 100% 100%;
                    }
                    .select_text {
                        padding:0 20px 0 10px;
                        height:40px;
                        line-height:36px;
                    }
                    .select_ul{ 
                        display:none;
                        position:absolute;
                        top:40px;
                        left:-1px;
                        width:100%;
                        border:1px solid #D4D4D4;
                        border-bottom-right-radius:3px;
                        border-bottom-left-radius:3px;
                        background:#fff;
                        color: #202020;
                        max-height: 350px;
                        overflow-y: auto;
                        li {
                            line-height:36px;
                            text-indent:10px;
                            &:hover {
                                color:#fff;
                                background:#6AA7EA;
                            }
                            &.cur {
                                color:#fff;
                                background:#195DA3;
                            }
                        }
                    }
                    &.change1 {
                        .select_ul {
                            li {
                                &:first-child {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                input {
                    display: block;
                    width: 120px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background-color: #c21010;
                    border-radius: 1px;
                    font-family: D-DIN-Bold;
                    font-size: 20px;
                    color: #ffffff;
                    border: none;
                    outline: none;
                    cursor: pointer;
                }
            }
        }
    }
    .section1 {
        @include res(margin-top,100px,(2 / 5));
        @include res(margin-bottom,170px,(2 / 5));
        .tyre-nav {
            @include res(margin-bottom,60px,(2 / 5));
            @include res(overflow-x,hidden,(xs:auto));
            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                @include res(width,100%,(xs:fit-content));
                li {
                    padding: 6px 10px;
                    border-radius: 2px;
                    transition: all .3s;
                    @include res(margin-right,48px,(20 / 48));
                    @include res(font-size,18px,(16 / 18));
                    color: #202020;
                    @include res(white-space,wrap,(xs:nowrap));
                    &.active,&:hover {
                        background-color: #01053b;
                        color: #fff;
                    }
                }
            }
        }
        .swiper-list {
            .swipers {
                display: none;
                position: relative;
                &.active {
                    display: block;
                }
                .swiper-button-next {
                    @include res(top,37%);
                    @include res(right,-1.2rem,(xs:0));
                    @include res(width,50px,(4 / 5));
                    @include res(height,50px,(4 / 5));
                    box-sizing: border-box;
                    border-radius: 2px;
                    border: solid 1px #7d7d7d;
                    transition: all .3s;
                    span {
                        @include res(font-size,28px,(4 / 5));
                        color: #202020;
                        transition: all .3s;
                    }
                    &:after {
                        content:'';
                    }
                    &:hover {
                        background: #C21010;
                        border-color: #C21010;
                        span {
                            color: #fff;
                        }
                    }
                }
                .swiper-button-prev {
                    @include res(top,37%);
                    @include res(left,-1.2rem,(xs:0));
                    @include res(width,50px,(4 / 5));
                    @include res(height,50px,(4 / 5));
                    box-sizing: border-box;
                    border-radius: 2px;
                    border: solid 1px #7d7d7d;
                    transition: all .3s;
                    span {
                        @include res(font-size,28px,(4 / 5));
                        color: #202020;
                        transition: all .3s;
                    }
                    &:after {
                        content:'';
                    }
                    &:hover {
                        background: #C21010;
                        border-color: #C21010;
                        span {
                            color: #fff;
                        }
                    }
                }
            }
            .swiper {
                .swiper-slide {
                    .pic {
                        @include res(width,fit-content,(xs:50%));
                        @include res(margin,0,(xs:auto));
                        @include res(height,441px,(lg:360px,mmd:330px,md:265px,xs:auto));
                        img {
                            @include res(width,100%,(xs:fit-content));
                            margin: auto;
                            max-width: 100%;
                            @include res(height,100%);
                        }
                    }
                    .text {
                        margin-top: 10px;
                        h2 {
                            font-family: D-DIN-Bold;
                            @include res(font-size,36px,(3 / 5));
                            width: fit-content;
                            margin: auto;
                            color: #b2b2b2;
                            transition: all .3s;
                            margin-bottom: 5px;
                        }                                                
                        p {
                            font-family: D-DIN-Bold;
                            @include res(font-size,16px,(12 / 16));
                            padding: 3px 12px;
                            color: #b2b2b2;
                            transition: all .3s;
                            border-radius: 13px;
                            border: solid 1px #b2b2b2;
                            width: fit-content;
                            margin: auto;
                        }
                    }
                    &:hover {
                        .text {
                            h2 {
                                color: #01053b;
                            }
                            p {
                                color: #c21010;
                                border-color: #c21010;
                            }
                        }
                    }
                }
            }
        }
    }
    .section2 {
        background: url(./images/index-bg.png)no-repeat;
        background-size: cover;
        .top {
            position: relative;
            .pic {
                img {
                    max-width: 100%;
                }
            }
            .container {
                .left {
                    position: relative;
                    @include res(width,724px,(xs:96%));
                    @include res(margin,0,(xs:auto));
                    .text {
                        position: relative;
                        @include res(top,-47px);
                        @include res(margin-bottom,20px);
                        h2 {
                            font-family: D-DIN-Bold;
                            @include res(font-size,66px,(3 / 5));
                            color: #202020;
                            margin-bottom: 10px;
                        }
                        p {
                            @include res(font-size,18px,(16 / 18));
                            @include res(line-height,28px,(16 / 18));
                            color: #202020;
                        }
                        a {
                            display: block;
                            width: fit-content;
                            color: #202020;
                            @include res(font-size,16px,(14 / 16));
                            @include res(margin-top,20px);
                            transition: all .3s;
                            img {
                                @include res(margin-left,16px);
                            }
                            &:hover {
                                .scroll-cont {
                                    div {
                                        color: #C21010;
                                        transform: translateY(-100%);
                                        img {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                            .scroll-cont {
                                overflow: hidden;
                                display: flex;
                                flex-direction: column;
                                @include res(height,20px,(xs:18px));
                                transition: all .3s;
                                div {
                                    display: flex;
                                    align-items: center;
                                    transition: all .25s;
                                    color: #202020;
                                    img {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                    .swiper {
                        .swiper-slide {
                            position: relative;
                            .videopic {
                                max-width: 100%;
                            }
                            .play {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: rgba(0,0,0,.3);
                                cursor: pointer;
                            }
                        }
                    }
                    .swiper-pagination {
                        left: 0;
                        right: auto;
                        width: fit-content;
                        @include res(bottom,-28px);
                        .swiper-pagination-bullet {
                            @include res(width,30px);
                            @include res(height,2px);
                            border-radius: 0;
                            background-color: #bfbfbf;
                            opacity: 1;
                            &.swiper-pagination-bullet-active {
                                background-color: #c21010;
                            }
                        }
                    }
                }
            }
            .pic {
                position: absolute;
                right: 0;
                @include res(top,-72px,(mmd:0));
                @include res(width,56.16%);
                @include res(display,block,(sm:none));
            }
        }
        .bottom {
            @include res(margin-top,160px,(2 / 5));
            @include res(padding-bottom,160px,(2 / 5));
            .container {
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include res(flex-wrap,nowrap,(xs:wrap));
                    li {
                        @include res(width,350px,(lg:24.3%,xs:48%));
                        position: relative;
                        @include res(margin-bottom,0,(xs:15px));
                        .more {
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 2px;
                            background: rgba(0,0,0,.4);
                            transition: all .3s;
                            img {
                                @include res(width,fit-content,(lg:80%));
                            }
                        }
                        &:hover {
                            .more {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
.video-model {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.8);
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
    .video {
        @include res(width,800px,(xs:100%));
        video {
            width: 100%;
        }
    }
    .out {
        position: absolute;
        @include res(top,1rem);
        @include res(right,2rem,(xs:20px));
        @include res(width,50px,(4 / 5));
    }
    
}
footer {
    @include res(display,block,(sm:none));
}
.yd-search {
    z-index: 9;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    padding: 0 10px;
    background: #fff;
    @include res(display,none,(sm:flex));
    justify-content: space-between;
    .select {
        position:relative;
        width:24%;
        width:24%;
        height:36px;
        background-color: #202020;
        cursor:pointer;
        font-size: 14px;
        border-radius: 1px;
        color: #fff;
        &:after {
            content:"";
            position:absolute;
            top:50%;
            right:10px;
            margin-top:-2px;
            background: url(./images/index-select.png)no-repeat;
            width: 10px;
            height: 6px;
            background-size: 100% 100%;
        }
        .select_text {
            padding:0 20px 0 10px;
            height:36px;
            line-height:36px;
        }
        .select_ul{ 
            display:none;
            position:absolute;
            bottom:36px;
            left:-1px;
            width:100%;
            border:1px solid #D4D4D4;
            border-bottom-right-radius:3px;
            border-bottom-left-radius:3px;
            background:#fff;
            max-height: 350px;
            overflow-y: auto;
            color: #202020;
            li {
                line-height:36px;
                text-indent:10px;
                &:hover {
                    color:#fff;
                    background:#6AA7EA;
                }
                &.cur {
                    color:#fff;
                    background:#195DA3;
                }
            }
        }
        &.change1_hd {
            .select_ul {
                li {
                    &:first-child {
                        display: none;
                    }
                }
            }
        }
    }
    input {
        display: block;
        width: 16%;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background-color: #c21010;
        border-radius: 1px;
        font-family: D-DIN-Bold;
        font-size: 14px;
        color: #ffffff;
        border: none;
        outline: none;
    }
}